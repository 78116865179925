import React from 'react'
import './skillset.css'
import {MdLibraryAddCheck} from 'react-icons/md'

const skillset = () => {
  return (
    <section id='skillset'>
      <h4>What Skills I Have</h4>
      <h2>My skillset</h2>

      <div className="container skillset__container">
        <div className="skillset__fe">
          <h3>Frontend Development</h3>
          <div className="skillset__content">
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>HTML & CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>JS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>Wordpress</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>NodeJS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}
        <div className="skillset__be">
        <h3>Backend Development</h3>
          <div className="skillset__content">
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>VBA & MYSQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>Java</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>C# & C++</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>Express</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='skillset__details'>
              <MdLibraryAddCheck className='skillset__details-icon'/>
              <div>
                <h4>Django</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default skillset