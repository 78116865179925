import React from 'react'
import './experiences.css'
import {BsCheck2Circle} from 'react-icons/bs'

const Experiences = () => {
  return (
    <section id='experiences'>
      <h4>What I Offer</h4>
      <h2>Experience</h2>

      <div className="container experiences__container">
        <article className="experience">
          <div className="experience__head">
            <h3>Canada Revenue Agency</h3>
            <h2>Information Technology Infrastructure Specialist</h2>
          </div>
          <ul className="experience__list">
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Resolved 600+ client and support tickets to date with a 95% approval rating using a self-help knowledge base.
</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Collaborated on the design and implementation of technology solutions, slashing repeat inquiries by 70%.</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Utilized PowerShell with enterprise applications such as Active Directory and Entrust for on-site and remote support</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Installed, configured, and maintained a variety of specialized tax software 
                within Microsoft suites.</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Composed VBA macro applications in Access & Excel to reduce reporting & and tracking protocols by 25%.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="experience">
          <div className="experience__head">
            <h3>Canada Revenue Agency</h3>
            <h2>Fullstack Software Developer</h2>
          </div>
          <ul className="experience__list">
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Developed full-stack MS Access and Excel applications with VBA and SQL using ADO objects for communication</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Consecutively managed 10+ workflows consisting of feature requests & bug fixes across 50+ applications</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Manufactured VBA modules to service data scraping and analysis from the cloud COBOL mainframe. </p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Refined apps to maximize network and storage efficiency across a remote infrastructure with confidentiality.</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Successfully settled 98% of client requests within the allotted service level objective</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEV*/}
        <article className="experience">
          <div className="experience__head">
            <h3>Rehab Plus</h3>
            <h2>IT Administrator</h2>
          </div>
          <ul className="experience__list">
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Cultivated website using JS and PHP leading to an increase in website traffic by 21% with a conversion rate of 4%</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Digitized administrative protocols within the existing clinical interface to expedite customer intake by 65%.</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Optimized network performance to re-integrate switches and routers with novel hardware devices.</p>
            </li>
            <li>
              <BsCheck2Circle className='experience__list-icon'/>
              <p>Innovated network infrastructure and operational capacities while preserving Windows server configuration</p>
            </li>
          </ul>
        </article>
        {/* END OF Content Creation */}
      </div>
    </section>
  )
}

export default Experiences 