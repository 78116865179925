import React from 'react'
import './about.css'
import ME from '../../assets/pics/me-about.png'
import {FaAward} from 'react-icons/fa'
import {AiOutlineUsergroupAdd} from 'react-icons/ai'
import {BsFolderPlus} from 'react-icons/bs'


const About = () => {
  return (
    <section id='about'>
      <h4>Get to Know</h4>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h4><a href="#skillset">Skillset</a></h4>
              <small>Frontend + Backend</small>
            </article>
            <article className='about__card'>
              <AiOutlineUsergroupAdd className='about__icon'/>
              <h4><a href="#experiences">Experience</a></h4>
              <small>3+ years of Professional Experience</small>
            </article>
            <article className='about__card'>
              <BsFolderPlus className='about__icon'/>
              <h4><a href="#portfolio">Projects</a></h4>
              <small>25+ Completed Projects</small>
            </article>
          </div>

          {/* <p>
          Welcome to my portfolio website! With a degree in Computer Science + Psychology and valuable professional experience, I have honed my technical skills and problem-solving abilities. From designing and implementing new technology solutions to optimizing websites and handling diverse technical challenges, I have consistently demonstrated my commitment to excellence. My proficiency in various programming languages, operating systems, and database management systems, coupled with my interpersonal and communication skills, make me a well-rounded software developer. I invite you to explore my portfolio and discover how my skills and enthusiasm can contribute to your software development needs. Whether you are looking for web applications, mobile solutions, or software enhancements, I am confident in my ability to deliver high-quality results. Thank you for visiting my website, and I look forward to the opportunity to collaborate with you.  
          </p> */}
          <br />
          
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About