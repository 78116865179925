import './contact.css'
import {HiMail} from 'react-icons/hi'
import {BsFacebook} from 'react-icons/bs'
import {FaWhatsappSquare} from 'react-icons/fa'
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('gmail to me', 'template_3y4enq6', form.current, '60Ge8vb0jNji-il6t')

    e.target.reset()
  };
  return (
    <section id='contact'>
      <h4>Get in Touch</h4>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <HiMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h4>arsh.pelia1@gmail.com</h4>
            <a href="mailto:arsh.pelia1@gmail.com" target='_blank'>Send a Message</a>
          </article>
          {/* <article className="contact__option">
            <BsFacebook className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h4>Arsh Pelia</h4>
            <a href="https://m.me/arsh.pelia" target='_blank'>Send a Message</a>
          </article> */}
          {/* <article className="contact__option" >
            <FaWhatsappSquare className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h4>+16477390899</h4>
            <a href="https://wa.me/16477390899" target='_blank'>Send a Message</a>
          </article> */}
        </div>
          {/* END OF CONTACT OPTIONS */}
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required/>
            <input type="text" name="email" placeholder="Your Email" required/>
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          </form>

      </div>
    </section>
  )
}

export default Contact 