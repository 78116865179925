import ReactReact, {useState, useEffect} from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Skillset from './components/skillset/Skillset'
import Experiences from './components/experiences/Experiences'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer' 

// https://www.youtube.com/watch?v=G-Cr00UYokU @2:40:12

// google analytics
import ReactGA from 'react-ga4';
ReactGA.initialize('G-BB4Z4R4GD0');

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <Header/>
      <Nav/>
      <About/>
      <Skillset/>
      <Experiences/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </>
  )
}

export default App