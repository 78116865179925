import React from 'react'
import './header.css'
import CTA  from './CTA'
// import ME from '../../assets/pics/me.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h3>Hello, I'm</h3>
        <h1>Arsh Pelia</h1>
        <h4 className="text-light">Software Developer</h4>
        <CTA/>
        <HeaderSocials/>

        {/* <div className="me">
          <img src={ME} alt="me" />
        </div> */}

        <a href="#contact" className="scroll__down">Scroll Down</a>

      </div>
    </header>
  )
}

export default Header
