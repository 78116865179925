import React from 'react'
import './footer.css'
import {GrLinkedin} from 'react-icons/gr'
import {BsGithub} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Arsh</a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skillset">skillset</a></li>
        <li><a href="#experiences">Experiences</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/arshjit-pelia-585029207/" target='_blank'><GrLinkedin/></a>
        <a href="https://github.com/ArshPelia" target='_blank'><BsGithub/></a>
        {/* <a href="https://www.linkedin.com/in/arshjit-pelia-585029207/" target='_blank'></a> */}
        </div>

        <div className="footer__copyright">
          <small>&copy; Arsh Pelia. All rights Reserved</small>
          <br />
          <small>Powered by React-JS</small>

        </div>
    </footer>
  )
}

export default Footer