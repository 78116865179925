import React, {useState} from 'react'
import Modal from 'react-modal';
import './portfolio.css'
import ESImage from '../../assets/pics/portfolio-graph.jpg';
import twitterImage from '../../assets/pics/portfolio-twitter-sentiment.png';
import gameImage from '../../assets/pics/portfolio-game.jpg';
// import bookingImage from '../../assets/pics/portfolio-code.jpg';
// import algoImage from '../../assets/pics/portfolio-algo.jpg';
import rehabImage from '../../assets/pics/portfolio-rehab.png';
// import sortingImage from '../../assets/pics/portfolio-sorting.jpg';
import cs50Image from '../../assets/pics/portfolio-cs50.jpg';
import leetImage from '../../assets/pics/portfolio-leetcode.jpg';
import recipeImage from '../../assets/pics/portfolio-recipe.jpg';
import odinImage from '../../assets/pics/odinproject.png';
import banterBoxImage from '../../assets/pics/banterbox.jpg';

// import budgetEDdemo from '../../assets/vids/demo-budgetES.mp4';

import {MdLibraryAddCheck} from 'react-icons/md'





const budgetESdemo = "https://www.youtube.com/embed/v2Me5QMeQB8?si=fZpPxoJPTcwisRek"
const leetDemo = "https://www.youtube.com/embed/-iAGadt4kgk?si=glv5vvMQ-aPGYjE9"
// const AlgoVisualizerDemo = "https://www.youtube.com/embed/VsFCn2SGLks?si=-FfzmUweHxewRidx"
const gameDemo = "https://www.youtube.com/embed/rEOnvc7m8eg?si=EegEv7yyi-H-3GFz" 
const recipeDemo = "https://www.youtube.com/embed/MoqqXVhiGJk?si=0HVVnLIN93W7xYPm"
const banterDemo = "https://www.youtube.com/embed/8RMrLCYk-FQ?si=AYGRjxhh5ia7ccvj"
// const rehabDemo = ""
// const searchAlgoDemo = "https://www.youtube.com/embed/veav_yUSEV4?si=NY4983P3De7-ek_q"
// const sortAlgoDemo = "https://www.youtube.com/embed/skM_lguor0A?si=4kGuPfHhZffgmyuI"


const data = [
	{
		id:1,
		image: banterBoxImage,
		title: 'Social Media App',
		lang: 'NodeJS, SocketIO, Express, MongoDB',
		github: 'https://github.com/ArshPelia/Messenger-App',
		demo: banterDemo,
		info: 'BanterBox is a modern full-stack blog application with the foundation of Node.js and Express.js. Leveraging the power of MongoDB, the backend architecture is designed for optimal performance, ensuring seamless scalability and adaptability. Real-time communication capabilities are seamlessly integrated using WebSocket technology, allowing for dynamic and interactive chat functionalities. For security, user authentication is fortified through the meticulous implementation of JSON Web Tokens (JWT), ensuring robust protection of user data. On the frontend, the application boasts a meticulously designed interface styled with Bootstrap, delivering a responsive user experience that captivates users.'
	},	
	{
		id:2,
		image: odinImage,
		title: 'OdinProject JS',
		lang: 'JS, Node, Express, React, Django',
		github: 'https://github.com/ArshPelia/OdinProject-JS',
		info: 'Throughout the course, I\'ve honed my proficiency in HTML, CSS, and JavaScript, mastering frontend frameworks like Bootstrap and React.js for building dynamic and responsive web applications. On the backend, I\'ve developed robust server-side APIs using Node.js and Express.js, integrating MongoDB and Mongoose for efficient database management. With a focus on full-stack development, I\'ve seamlessly integrated frontend and backend components to create fully functional web applications, implementing user authentication and authorization for enhanced security. Additionally, I\'m well-versed in version control using Git and GitHub, and proficient in deploying applications to platforms like Heroku and Netlify. Through a collection of projects, I showcase my expertise in building responsive websites, web applications, and interactive projects.'
	},	
	{
		id:3,
		image: leetImage,
		title: 'Leetcode Progression Tracker',
		lang: 'Python, Django, Javascript',
		github: 'https://github.com/ArshPelia/Leetcode-Tracker',
		demo: leetDemo,
		info: 'The Leetcode Tracker Web Application, meticulously developed using HTML5, CSS3, JavaScript, and Django, revolutionizes your coding journey. Seamlessly integrating with LeetCode, this platform enables effortless problem management, progress tracking, and collaborative note-taking. Dynamic filters empower users to customize their learning path, while interactive data visualization offers insights into their performance. The Leetcode Tracker is your ultimate companion for conquering coding challenges.'
	},
	{
		id:4,
		image: ESImage,
		title: 'Financial Expert System',
		lang: 'Python',
		github: 'https://github.com/ArshPelia/Budgeting-Expert-System',
		demo: budgetESdemo,
		info: 'The system consists of a forward-chaining model as it calculates the status of all categories based on the facts that are deduced from financial activity and proposes recommendations if criteria for the predefined set of rules is met. Subsequently, each category is sorted by status from critical to optimal based on the highest severity of inferences and recommendations are provided. Inferences are created by searching for the existance of facts based on user information pertaining to debt and transactional history and providing a recommendation based on its corresponding rule.'
	},
	{
		id:5,
		image: recipeImage,
		title: 'Recipe Generator',
		lang: 'React Native, JavaScript',
		github: 'https://github.com/ArshPelia/Recipe-App',
		demo: recipeDemo,
		info: 'The Recipe Builder is a React-based web application that enables users to search for recipes by entering ingredients and applying filters for health, diet, and meal type preferences. It utilizes the Edamam API to fetch and display recipe results in an attractive and responsive user interface. Users can click on recipe cards to view ingredients and open cooking directions in a new tab. The application employs React Hooks for state management, conditional rendering for modals, and CSS media queries for responsive design. It also handles API requests and errors effectively, while environmental variables safeguard sensitive data. This project demonstrates proficiency in front-end development, API integration, responsive design, and showcases a commitment to delivering user-friendly, visually appealing solutions.'
	},
	{
		id:6,
		image: twitterImage,
		title: 'Sentiment Evaluation on Twitter',
		lang: 'Python',
		github: 'https://github.com/ArshPelia/HateSpeech-Sentiment-Analysis-Model-for-World-Cup-2022-Tweets',
		info: 'The Twitter Sentiment Analysis application in Python aims to perform sentiment analysis on tweets related to the 2022 World Cup. It trains a classification model to automate labeling and implements hate speech detection on negative tweets. Additionally, the application constructs user networks associated with hateful or offensive subjects. Contributions include proposing an efficient classification model, comparing Naive-Bayes and Support Vector Machine models, extending research findings on opinion-mining tweets, and introducing a method to compile dangerous user networks. Overall, it provides a comprehensive approach to analyzing sentiments and identifying harmful content on Twitter.'
	},
	{
		id:7,
		image: gameImage,
		title: '2D Action-Adventure Platformer',
		lang: 'Unity, C#',
		github: 'https://github.com/ArshPelia/2DSpacePlatformer',
		demo: gameDemo,
		info: 'A final project submission for a Game Design Course, the 2D game was designed entirely in the UNITY engine with minmally imported assets and C# scripts. The gameplay primarily consists of an exchange between rigourous platformer, melee and ranged combat accentuated by boss battles at the culmination of each stage. '
	},
	{		
		id:8,
		image: rehabImage,
		title: 'Commercial Website',
		lang: 'PHP, JavaScript, CSS',
		github: 'https://github.com/ArshPelia/RehabPlus',
		info: 'Developed across the duration of a Computer Science COOP placement, the website comprises of 20+ pages delineating various offers and services provided by the establishment. The commercial gym/physio website has been developed using PHP, JavaScript, and CSS. This dynamic website provides a comprehensive platform for individuals seeking fitness services and physiotherapy. The PHP backend ensures smooth data handling and enables functionalities such as user registration, membership management, and appointment scheduling. JavaScript enhances the interactivity of the website, allowing for dynamic content updates and interactive features like live chat support and exercise demonstrations.'

	},
	{
		id:9,
		image: cs50Image,
		title: 'Harvard CS50: Web Programming',
		lang: 'Django, JavaScript, MySQL',
		github: 'https://github.com/ArshPelia/CS50-Web-Programming',
		info: 'Completed Harvard`s CS50 Web Programming with Python and JavaScript, a comprehensive online course that equipped me with fundamental skills in web development. Gained proficiency in HTML, CSS, and JavaScript for building interactive and responsive user interfaces, along with a strong understanding of server-side programming using Python and Flask. Acquired expertise in working with relational databases, including database design and querying using SQL and ORM libraries. Developed secure and scalable web applications, integrating RESTful APIs and mastering version control with Git. Through practical projects, I honed my ability to create dynamic web applications, implementing modern front-end frameworks like React. This course provided a solid foundation for creating sophisticated and efficient web solutions.' 
	}
	// {		
	// 	id:6,
	// 	image: algoImage,
	// 	title: 'Search & Sorting Algorithm Visualizer',
	// 	lang: 'Python',
	// 	github: 'https://github.com/ArshPelia/Algo-Visualizer',
	// 	demo: AlgoVisualizerDemo,
	// 	info: 'Presents a visual interface to depict various search and sorting algorithms Pygame & tkinter interfaces. Features a Grid space powered by Pygame which allows users to select a finish, start and barrier nodes prior to selecting a search algorithm to find the target. Also, the sorting application showcases the efficiency of sorting a randomized array'
	// },
	// {
	// 	id:9,
	// 	image: bookingImage,
	// 	title: 'Booking Management Website',
	// 	lang: 'HTML, CSS, JavaScript, PHP, MySQL',
	// 	github: 'https://github.com/ArshPelia/Training-Manager',
	// 	info: 'Booking website for an athletic training organization with fully integrated CSS, and a scheduling system for clients.  PHP is utilized for the backend, enabling dynamic functionalities such as user registration, account management, and a robust scheduling system for clients. The website offers detailed information about the soccer training organization, including coaching staff, training programs, and facilities. The scheduling system allows clients to easily book training sessions, view availability, and make payments online.' 
	// },
		// {		
	// 	id:6,
	// 	image: sortingImage,
	// 	title: 'Sorting Algorithm Visualizer',
	// 	lang: 'Python',
	// 	github: 'https://github.com/ArshPelia/sort-visual-java',
	// 	demo: sortAlgoDemo,
	// 	info: 'Presents a visual interface to depict various sorting algorithms on randomized arrays using the .'                                                                  
	// },


]


const Portfolio = () => {
	const [modalIsOpen, setModalIsOpen] = useState(null);
	const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
	const [videoSrc, setVideoSrc] = useState('');
  
	const openModal = (id) => {
	  setModalIsOpen(id);
	};
  
	const closeModal = () => {
	  setModalIsOpen(null);
	};
  
	const openVideoModal = (demoUrl) => {
	  setVideoSrc(demoUrl);
	  setVideoModalIsOpen(true);
	};
  
	const closeVideoModal = () => {
	  setVideoModalIsOpen(false);
	};
  
	return (
	  <section id='portfolio'>
		<h4>My Recent Work</h4>
		<h2>Portfolio</h2>
  
		<div className='container portfolio__container'>
		  {data.map(({ id, image, title, lang, github, demo, info }) => {
			return (
			  <article key={id} className='portfolio__item'>
				<div className='portfolio__item-image'>
				  <img src={image} alt={title} />
				</div>
				<h3>{title}</h3>
				<h4>{lang}</h4>
				<div className='portfolio__item-cta'>
				  <a
					href={github}
					className='btn'
					target='_blank'
					rel='noopener noreferrer'
				  >
					GitHub
				  </a>
				  <a href='#' onClick={() => openModal(id)} className='btn'>
					Read More
				  </a>
					{demo && demo.trim() !== '' && ( // Conditionally render the "View Demo" button
					<a href='#' onClick={() => openVideoModal(demo)} className='btn'>
						View Demo
					</a>
					)}
				  <Modal
					isOpen={modalIsOpen === id}
					onRequestClose={closeModal}
					className='modal'
					overlayClassName='modal-overlay'
				  >
					<div className='modal-content'>
						<div className='modal-header'>
							<h2 className='modal-title'>{title}</h2>
						</div>
						<div className='modal-body'>
							<h4 className='modal-lang'>{lang}</h4>
							<p>{info}</p>
						</div>
						<div className='modal-footer'>
							<button onClick={closeModal}>Close</button>
						</div>
					</div>
				  </Modal>
				</div>
			  </article>
			);
		  })}
		</div>
  
		<Modal
		  isOpen={videoModalIsOpen}
		  onRequestClose={closeVideoModal}
		  className='modal'
		  overlayClassName='modal-overlay'
		>
		  <div className='modal-content'>
			<div className='modal-header'>
			  <h2 className='modal-title'>Demo Video</h2>
			</div>
			<div className='modal-body'>
			  {/* Embed YouTube video using an iframe */}
			  <iframe
				width="560"
				height="315"
				src={videoSrc}
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
			  ></iframe>
			</div>
			<div className='modal-footer'>
			  <button onClick={closeVideoModal}>Close</button>
			</div>
		  </div>
		</Modal>
		
		<br />

		<div className="container misc__container">
			<div className="misc__fe">
				<h3>Miscellaneous Projects</h3>
				<div className="misc__content">
					<article className='misc__details'>
						<MdLibraryAddCheck className='misc__details-icon'/>
						<div>
							<h4>Booking Management Site</h4>
							<small className='text-light'>PHP</small>
						</div>
						</article>
					<article className='misc__details'>
					<MdLibraryAddCheck className='misc__details-icon'/>
					<div>
						<h4>Hypertree generator</h4>
						<small className='text-light'>XML, Python</small>
					</div>
					</article>
					<article className='misc__details'>
					<MdLibraryAddCheck className='misc__details-icon'/>
					<div>
						<h4>Search & Sort Algorithm Visualizer</h4>
						<small className='text-light'>Java, Python</small>
					</div>
					</article>
					<article className='misc__details'>
					<MdLibraryAddCheck className='misc__details-icon'/>
					<div>
						<h4>Artificial Neural Networks</h4>
						<small className='text-light'>Python</small>
					</div>
					</article>

				</div>
			</div>
		</div>
	  </section>
		
	  
	);
  };
  
  export default Portfolio;
	